// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-board-tsx": () => import("./../../src/pages/board.tsx" /* webpackChunkName: "component---src-pages-board-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-newsroom-tsx": () => import("./../../src/pages/newsroom.tsx" /* webpackChunkName: "component---src-pages-newsroom-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-staff-tsx": () => import("./../../src/pages/staff.tsx" /* webpackChunkName: "component---src-pages-staff-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-staff-member-tsx": () => import("./../../src/templates/staffMember.tsx" /* webpackChunkName: "component---src-templates-staff-member-tsx" */)
}

